
import WDElement from '../wd-element';
import WDButton from '../button/wd-button';
import WDNavbar from '../navbar/wd-navbar';

export default class WDCard extends WDElement {

  #body = null;
  #header = null;
  #footer = null;
  
  constructor() {
    super().classList.add('wd-card');
    return this;
  };

  disabledDisconnectedCallback()
  {
    this.replaceChildren();
    this.#body = null;
    this.#header = null;
    this.#footer = null;
  };

  body()
  {
    if (!this.#body)
    {
      this.#body = new WDCardBody();
      this.append(this.#body);
    }
    
    return this.#body;
  };

	header()
  {
    if (!this.#header) 
    {
      this.#header = new WDCardHeader();
      this.body().before(this.#header);
    }
    return this.#header;
  };

	footer()
  {
    if (!this.#footer) 
    {
      this.#footer = new WDCardFooter();
      this.body().after(this.#footer);
    }
    return this.#footer;
  };

  //convenience methods
	navbar() { return this.header().navbar(); };
	toolbar() { return this.footer().navbar(); };
	title() { return this.header().title(); };
	
};

export class WDCardHeader extends WDElement {

  _title = null;
  _buttons = null;
  _navbar = null;

  constructor()
  {
    super().classList.add('wd-card-header');
    return this;
  };

  title(str)
  {
		if (!this._title)
		{
			 this._title = wdc('<div class="wd-card-header-title"/>');
			 this.prepend(this._title);
		}
			
    this._title.innerText = str;
			
    return this._title; 
  };

  navbar()
  {
		if (!this._navbar)
		{
			this._navbar = new WDNavbar();
			this.append(this._navbar).classList.add('has-navbar');
		}
			
		return this._navbar;
	};

	buttons()
	{
		if (!this._buttons)
		{
			 this._buttons = wdc('<div class="wd-card-header-buttons"/>');
			 this.append(this._buttons);
		}
			
		return this._buttons; 
	};

	addButton(cfg)
	{
	  let btn = new WDButton(cfg);
		this.buttons().append(btn);
		return btn;
	};

};

export class WDCardFooter extends WDElement {

  _title = null;
  _buttons = null;
  _navbar = null;

  constructor()
  {
    super().classList.add('wd-card-footer');
    return this;
  };

  buttons()
  {
		if (!this._buttons)
		{
			 this._buttons = wdc('<div class="wd-card-footer-buttons"/>');
			 this.prepend(this._buttons);
		}
			
		return this._buttons; 
	};

	addButton()
	{
	  let btn = new WDButton();
    this.buttons().append(btn);
    return btn;
  };

  addSpacer()
  {
    const spacer = wdc('<div class="wd-card-footer-spacer"/>');
    this.buttons().append(spacer);
  };
  
  navbar()
  {
		if (!this._navbar)
		{
			this._navbar = new WDNavbar();
			this.append(this._navbar);
			this.classList.add('has-navbar');
		}
			
		return this._navbar;
	};

};

class WDCardBody extends WDElement {

  _title = null;
  _subtitle = null;
  
  constructor()
  {
    super().classList.add('wd-card-body');
    return this;
  };
		
};

customElements.define('wd-card',WDCard);
customElements.define('wd-card-header',WDCardHeader);
customElements.define('wd-card-footer',WDCardFooter);
customElements.define('wd-card-body',WDCardBody);
