
import WDElement from '../wd-element';
import WDNavbar from '../navbar/wd-navbar';

export default class WDCardTabs extends WDElement {

	#navbar;
	#body;
	#options = {
		navigation_mode: 'tabs',
		collapsible: false,
		breakpoint: 'md',
		tab_position: 'right'
	};

	constructor(opts)
	{
		super().classList.add('wd-card-tabs');
		
		if (opts) Object.assign(this.#options,opts);

		if (this.#options.collapsible)
		{
			this.tabMenu().collapsible({mode:'menu',breakpoint:this.#options.breakpoint});
			this.tabMenu().toggleButton().fasIcon('ellipsis-v');
		}
	};

	items() { return this.body().children; };
	itemArray() { return Array.from(...this.body().children); };

	navbar()
	{
		if (!this.#navbar)
		{
			this.#navbar = new WDNavbar();
			this.prepend(this.#navbar);
		}
		
		return this.#navbar;
	};

	tabMenu() 
	{
		return this.navbar()[this.#options.tab_position](); 
	};

	
	body()
	{
		if (!this.#body)
		{
			this.#body = wdc('<div class="card-tabs-body"/>');
			this.append(this.#body);
		}
		
		return this.#body;
	};

	reset()
	{
		this.body().replaceChildren();
		this.tabMenu().empty();
	};

	addCard(card,label,icon)
	{
		this.body().append(card);
		card.hide();
		
		const shortcode = 'wd-card-' + label.toLowerCase().replace(' ','');
		
		card.dataset.shortcode = shortcode;
		this.addCardNav(shortcode,label,icon);

		//if this is the first card, show it
		if (this.items().length == 1) this.showCard(shortcode);
		
		return card;
	};

	showCard(shortcode)
	{
		this.updateCardNav(shortcode);

		for (const card of this.items() )
		{
		
			if (card.dataset.shortcode == shortcode) 
			{
				card.trigger('card:show');
				card.show();
				card.trigger('card:shown');
								
				if (this.#options.collapsible == true)
				{
					this.tabMenu().toggleButton().text(cardObj.label);
					if (cardObj.icon) this.tabMenu().toggleButton().icon(cardObj.icon);
				}

			}
			else 
			{
				card.trigger('card:hide');
				card.hide();
				card.trigger('card:hidden');
			}
			
		}

	};

	addCardNav(shortcode,label,icon)
	{
		const btn = this.tabMenu().add({label:label, icon:icon, click:() => this.showCard(shortcode) } );
		btn.dataset.shortcode = shortcode;

		return btn;
	};
	
	updateCardNav(shortcode)
	{
		const sel = this.tabMenu().querySelector('.is-active');
		if (sel) sel.classList.remove('is-active');

		const tab = this.tabMenu().querySelector('[data-shortcode="' + shortcode + '"]');
		if (tab) tab.classList.add('is-active');

		//this.navbar().hideMenu();
	};

	getCardIndex(card)
	{
		let idx = -1;

		for (let i=0;i<this.items().length;i++)
		{
			if ( this.items()[i] == card)
			{
				idx = curIdx;
				break;
			}
		}
		
		return idx;
	};

	setCardLabelAt(idx,title)
	{
		const card = this.items()[idx];
		const btn = this.tabMenu().items()[idx].querySelector('.label');
		
		card.labelName = title;
		btn.text(title);
	}

	removeCardAt(idx)
	{
		this.tabMenu().removeAtIndex(idx);		
		this.items()[idx].remove();

		//show the first card
		if (this.items().length > 0)
		{
			this.showCard(this.items()[0].dataset.shortcode);
		}

	};

};

customElements.define('wd-card-tabs',WDCardTabs);
